import * as React from "react"
import '../css/App.css';
import { Link } from "gatsby";

// markup
const IndexPage = () => {
  return (
    <div className="font-sans">
      <div className="text-center">
        <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Steven Jones</h1>
      </div>
      <ul className="text-center flex flex-col">
        <li className="my-4"><Link to="/goodbudget/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Goodbudget tools</Link></li>
        <li className="my-4"><Link to="/covid-counter/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Covid vaccination counter</Link></li>
        <li className="my-4"><Link to="/bread-timer/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Bread timer</Link></li>
        <li className="my-4"><Link to="/roast-dinner/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Roast dinner</Link></li>
      </ul>
    </div>
  )
}

export default IndexPage
